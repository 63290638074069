import router from '@/router'
import FileSaver from 'file-saver'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import CreateNewProspectForm from './CreateNewProspectForm.vue'

const ProspectModule = namespace('ProspectModule')

@Component({
    name: 'ProspectListTable',
    filters: {
        truncate(value: string, length: number) {
            return value.length > length
                ? value.substr(0, length) + '...'
                : value
        }
    },
    components: {
        CreateNewProspectForm
    }
})
export default class ProspectListTable extends Vue {
    @ProspectModule.Getter
    private getIsLoading!: boolean;

    @ProspectModule.Getter
    private getPageNumber!: number;

    @ProspectModule.Getter
    private getSize!: number;

    @ProspectModule.Getter
    private getTotalPages!: number;

    @ProspectModule.Getter
    private getTotalElements!: number;

    @ProspectModule.Getter
    private getSortDirection!: string;

    @ProspectModule.Getter
    private getSortBy!: string;

    @ProspectModule.Getter
    private getProspects!: Array<any>;

    @ProspectModule.Action
    private fetchProspectList!: (args: { search: string }) => Promise<any>;

    @ProspectModule.Action
    private incrementPageNumber!: (args: { pageNumber: number, search: string }) => void;

    @ProspectModule.Action
    private changePerPageNumber!: (args: { perPage: number, search: string }) => void;

    @ProspectModule.Action
    private changeSort!: (args: { sort: any, search: string }) => void;

    //* Component level properties/attributes
    private createNewProspect = false;

    private selected: Object = new Object();
    private prospectSearchTerm = '';

    created(): void {
        this.fetchProspectList({ search: this.prospectSearchTerm })
    }

    mounted(): void {
        const _newProspectKeyListener = function (e: KeyboardEvent) {
            if (e.key === 'n' && e.altKey) {
                this.createNewProspect = true;
            }
        }

        document.addEventListener('keydown', _newProspectKeyListener.bind(this));
    }

    handleDropDownChangeForPageSize(value: string) {
        this.changePerPageNumber({ perPage: value as unknown as number, search: this.prospectSearchTerm })
    }

    //* Handle page-change event
    onPageChange(page: number) {
        this.incrementPageNumber({ pageNumber: page, search: this.prospectSearchTerm })
    }

    //* Handle sort event
    onSort(field: string, order: string) {
        this.changeSort({ sort: { field, order }, search: this.prospectSearchTerm })
    }

    //* Type style in relation to the value
    type(value: string) {
        const number = parseFloat(value)
        if (number < 1000000) {
            return 'is-warning'
        } else if (number >= 1000000 && number < 3000000) {
            return 'is-success'
        } else if (number >= 3000000) {
            return 'is-info'
        }
    }

    handleTableRowDoubleClick(row: any) {
        router.push(`/dashboard/prospect/${row.prospect_id}`)
    }

    exportProspectsToCsv() {
        // todo move/make a mixin or util
        if (this.getProspects.length <= 0) {
            // todo snackbar nothing to export
            return
        }

        //* Use first element to choose the keys and the order
        const keys = Object.keys(this.getProspects[0]).filter(key => key !== 'employee')

        //* Use the formatted headers (Title case and remove dashes)
        const headers = keys.map(header => header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))

        //* Build header
        let result = headers.join(',') + '\n'

        //* Add the rows
        this.getProspects.forEach((prospect) =>
            result += keys.map(k => prospect[k]).join(',') + '\n'
        )

        const blob = new Blob([result], { type: 'text/csv;charset=utf-8' })
        FileSaver.saveAs(blob, 'prospects.csv')
    }

    dispatchProspectSearch() {
        if (!this.prospectSearchTerm) {
            return
        }
        this.fetchProspectList({ search: this.prospectSearchTerm })
        this.prospectSearchTerm = ''
    }

    openProspectInNewTab(prospectId: number) {
        window.open(router.resolve(`/dashboard/prospect/${prospectId}`).href, '_blank')
    }
}
