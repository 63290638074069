import Util from '@/mixins/Util'
import { ProspectResponse } from '@/types/Prospect'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ProspectModule = namespace('ProspectModule')

declare namespace CreateNewProspectFormInterface {

    export interface Data {
        prospect_name: string;
        business: string;
        projection: number;
        mobile_no: string;
        landline_no: string;
        location?: any;
        email?: string;
        current_status: number | string;
        prospect_type: number | string;
        financial_health: number;
        general_knowledge?: string;
        business_knowledge?: string;
        risk_taking_attitude?: any;
        expected_margin?: any;
        trading_experience?: any;
        means_of_knowing?: any;
        profile_status?: any;
        prospect_interests?: any;
        remarks: string;
    }
}

@Component({
    name: 'CreateNewProspectForm'
})
export default class CreateNewProspectForm extends Vue {
    @ProspectModule.Action
    private createNewProspect!: (data: any) => Promise<any>;

    private open = true;

    private isLoading = false;
    private newProspect = {
        business: 'None'
    } as CreateNewProspectFormInterface.Data;
    private currentStatus = ['Uncalled', 'Low', 'Medium', 'High', 'Client'];
    private prospectType = ['Businessman', 'Self', 'Salaried', 'Retired'];
    private dataPlaceholderForAddressAutocomplete = [];
    private isFetchingAddressAutocomplete = false;
    private allCountries: Array<ProspectResponse.Country> = [];
    private selectedCountryObject: ProspectResponse.Country = {} as ProspectResponse.Country;
    private allCities: Array<ProspectResponse.City> = [];
    private selectedCityObject: ProspectResponse.City = {} as ProspectResponse.City;

    resetProspectForm() {
        this.newProspect = {
            mobile_no: '',
            current_status: 'Uncalled',
            prospect_type: 'Businessman',
            business: 'None',
            financial_health: 0,
            projection: 50000,
            general_knowledge: 'Fundamental Awareness',
            business_knowledge: 'Fundamental Awareness',
            trading_experience: 'No'
        } as CreateNewProspectFormInterface.Data
    }

    created() {
        this.resetProspectForm()
    }

    mounted(): void {
        const _newProspectKeyListener = async function (e: KeyboardEvent) {
            if (e.key === 'Enter' && e.altKey) {
                await this.dispatchCreateNewProspect();
            }
        }

        document.addEventListener('keydown', _newProspectKeyListener.bind(this));
    }

    @Watch('newProspect.prospect_name')
    prospectNameChanged() {
        this.newProspect.prospect_name = Util.titleSize(this.newProspect.prospect_name)
    }

    private async dispatchCreateNewProspect() {
        this.isLoading = true
        const response: any = await this.createNewProspect(this.newProspect)

        if (response.error) {
            this.$buefy.snackbar.open({
                duration: 15000,
                message: `${response.error}`,
                type: 'is-danger',
                position: 'is-bottom-left',
                actionText: 'Retry',
                queue: false,
                onAction: () => {
                    //* Try resubmitting the form
                    this.dispatchCreateNewProspect()
                }
            })
        } else {
            this.$buefy.snackbar.open(`Prospect created with Id: ${response.prospect_id}`)
            this.newProspect = {} as CreateNewProspectFormInterface.Data
            this.$emit('createNewProspectFormClosed')
        }
        this.isLoading = false
    }

    currencyFormatter(value: number | number | string) {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PKR'
        })
        return currencyFormatter.format(value as number)
    }
}
