












import { Component, Vue } from 'vue-property-decorator'
import Navbar from '../../components/layout/Navbar.vue'
import ProspectListTable from '../../components/dashboard/prospect/ProspectListTable.vue'

@Component({
  components: {
    Navbar,
    ProspectListTable
  }
})
export default class Prospect extends Vue {}
